import { Component, Input, ViewChild } from '@angular/core';
import { MarkdownService } from 'ngx-markdown';

// Environment
import { environment } from '../../../environments/environment';

// Models
import { PageModel } from '../../models/page.model';

@Component({
  selector: 'app-page-preview',
  templateUrl: './page-preview.component.html',
  styleUrls: ['./page-preview.component.css']
})
export class PagePreviewComponent {

  @Input('model') model: PageModel;

  public frameUrl: string;
  
  @ViewChild('previewForm') previewForm;

  constructor(private _markdownService: MarkdownService) { }

  ngOnChanges() {
    if (!this.model) return;

    // Load Preview
    this.frameUrl = `${environment.clientUrl}/${environment.adminPreviewToken}/Page/${this.model.token}`;
    setTimeout(() => { this._generatePreview(); }, 500);
  };

  private _generatePreview() {
    let previewForm = this.previewForm.nativeElement;
    previewForm.firstElementChild.value = `${this._markdownService.compile(this.model.content)}
      <style>${this.model.styles}</style>`;
      // Scripts have been left out as it's a XSS vulnerability
    previewForm.submit();
  }
}
