import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class MessagesService {

  constructor(private _http: HttpClient) { }

  public sendNotification(notification) {
    return this._http.post('notifications', notification);
  }
}