import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { of } from 'rxjs/observable/of'
import { catchError } from "rxjs/operators/catchError";

// Services
import { AuthenticationService } from '../authentication.service';

// Models
import { UserModel } from '../../models/user.model';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css']
})
export class SignUpComponent implements OnInit {

  public user: UserModel = <UserModel>{};
  private _code: string;
  public passwordError: string = null;
  public confPassword: string;
  public passwordRegex = '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[-_.])[A-Za-z\d$-_.].{5,24}';

  constructor(private _router: Router,
    private _authentication: AuthenticationService,
    private _routerActivate: ActivatedRoute) { }

  ngOnInit() {
    this._routerActivate.params.subscribe(params => {
      this._code = params['code'];
    });
  }

  public signUp() {
      if (this._code) {
        this._authentication.signUpWithCode(this.user, this._code).subscribe(
          (res) => { this._router.navigateByUrl('/sign-in'); },
          (err) => { console.log(JSON.stringify(err)); }
        );
      } else {
        this._authentication.signUp(this.user).subscribe(
          (res) => { this._router.navigateByUrl('/sign-in'); },
          (err) => { console.log(JSON.stringify(err)); }
        );
      }
  }

  public signIn() {
    this._router.navigateByUrl('/sign-in');
  }
}
