import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { Papa } from 'ngx-papaparse';
import { element } from 'protractor';
import { Subject } from 'rxjs';
import { BsModalService } from 'ngx-bootstrap';

// Components
import { LeavePageComponent } from '../leave-page/leave-page.component';

// Models
import { NotificationModel } from '../models/notification.model';
import { MediaItemModel } from '../models/mediaItem.model';

// Services
import { MessagesService } from './messages.service';
import { NotificationsService } from '../notifications/notifications.service';
import { MediaService } from '../media/media.service';

@Component({
  selector: 'app-messages',
  templateUrl: './messages.component.html',
  styleUrls: ['./messages.component.css']
})
export class MessagesComponent implements OnInit {

  public notification: NotificationModel = <NotificationModel>{};
  public file: File = null;
  public emailIncorrect: boolean = true;
  public emailDirty: boolean = false;
  public fileIncorrect: boolean = true;
  public fileDirty: boolean = false;
  public emailList: Array<any> = null;
  public emailInput: string = null;
  public csvFile: string = null;

  public attachmentMediaItem: MediaItemModel = <MediaItemModel>{};
  public attachmentList: Array<MediaItemModel> = <Array<MediaItemModel>>[];
  public attachments: Array<any> = <Array<any>>[];
  public attachmentIncorrect: boolean = false;
  public attachmentDirty: boolean = false;
  public attachmentSize: number = 0;
  public attachmentSizeIncorrect: boolean = false;
  
  @ViewChild('notificationForm') form: any;

  private _emailRegex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);

  constructor(private _papaParse: Papa,
    private _messageService: MessagesService,
    private _notifications: NotificationsService,
    private _media: MediaService,
    private _modalService: BsModalService,) { }

  ngOnInit() {
  }

  public fileUploaded(event) {
    this.fileDirty = true;
    let reader = new FileReader();
    if(event.target.files && event.target.files.length > 0) {
      this.file = event.target.files[0];
      if (this.file.name.match('\.' + 'csv' + '$')) {
        this.fileIncorrect = false;
        this.emailIncorrect = false;
        this.parseCSV(this.file);
      }
      else this.fileIncorrect = true;
    }
  }

  public attachmentUploaded(event) {
    let reader = new FileReader();
    this.attachmentDirty = true;
    if(event.target.files && event.target.files.length > 0) {
     var attachment = event.target.files[0];
      if (attachment.name.match('\.' + 'pdf' + '$') || attachment.name.match('\.' + 'doc' + '$') || attachment.name.match('\.' + 'docx' + '$')) {
        
        this.attachmentIncorrect = false;
        this.attachmentSize += attachment.size;
        if (this.attachmentSize / 1024 < 20482) {
          this.attachmentSizeIncorrect = false; // can`t be more than 20MB
          this._media.uploadNotificationFile(attachment).subscribe(
            (res) => {  
              this._media.listNotificationFiles().subscribe(
                (res) => {
                  this.attachmentList = <Array<MediaItemModel>>res;
                  this.attachments.push({originalName: attachment.name, name: this.attachmentList[0].name, _id: this.attachmentList[0]._id, size: parseFloat((attachment.size / 1024).toFixed(2))});
                },
                (err) => { console.log(JSON.stringify(err)); }
              )
               },
            (err) => { console.log(JSON.stringify(err)); }
          );
        }
        else this.attachmentSizeIncorrect = true;
      }
      else this.attachmentIncorrect = true;
    }
  }

  public removeFile() {
    this.csvFile = '';
    this.file = null;
    this.emailIncorrect = true;
    this.emailList = null;
  }

  public removeAttachment(item) {
    this.attachments = this.attachments.filter(element => { 
      return element !== item
    });
    this._media.deleteImage(item._id).subscribe();
  }

  public parseCSV(file: any) {
    this.emailList = [];
    this._papaParse.parse(file, {
      header: true,
      step: (results, parser) => {
        if (results.errors.length)
          console.log('Errors: ', results.errors);
        else if (results.data[0] && results.data[0].email !== '') {
          this.emailList.push(
            <any>{
              email: results.data[0].email,
              isDuplicate: this.checkIfDuplicateEmail(results.data[0].email)
            });
        }
      },
      complete: () => this._isEmailValid()
    });
  }

  public onEmailChange() {
    if (this.emailInput) {
      this.emailDirty = true;
      this.removeFile();
    }
    else this.emailDirty = false;
    this.emailList = [];
    
    if (this.emailInput.length > 0) {
      this.emailInput = this.emailInput.trim().replace(/\s\s+/g, ' ').replace(/\s/g, ' ');
      var tempArray = this.emailInput.split(/[ ,]+/);
      tempArray.forEach(element => {
        this.emailList.push(
          <any>{
            email: element,
            isDuplicate: this.checkIfDuplicateEmail(element)
          });
      })
      this._isEmailValid();
    }
  }

  private _isEmailValid() {
    this.emailIncorrect = false;
    this.emailList.forEach(element => {
      if (!this._emailRegex.test(element.email)) {
        this.emailIncorrect = true;
        element.invalid = true;
      } else {
        element.invalid = false;
      }
    });
  }

  public checkIfDuplicateEmail(email: string) {
    return this.emailList.filter(item => {
      return item.email === email;
    }).length > 0 ? true : false;
  }

  public sendNotification() {
    let templateEmailList: Array<string> = <Array<string>>[];

    this.emailList.forEach(element => {
      templateEmailList.push(element.email);
    });
    templateEmailList = Array.from(new Set(templateEmailList));
    
      this._messageService.sendNotification(
        <NotificationModel>{
          emails: templateEmailList,
          subject: this.notification.subject,
          content: `${this.notification.content}\n\n\nIf you want to unsubscribe from the SCEE notification list, send a message to info@scee-conferences.org.`,
          attachment: this.attachments
        }).subscribe(
          (res) => { this._notifications.message.emit('Email was sent.'); },
          (err) => { console.log(JSON.stringify(err)); }
        );
  }

}
