import { Component, OnInit, HostListener } from '@angular/core';
import { Subject } from 'rxjs/Subject';
import { BsModalRef } from 'ngx-bootstrap';

@Component({
  selector: 'app-leave-page',
  templateUrl: './leave-page.component.html',
  styleUrls: ['./leave-page.component.css']
})
export class LeavePageComponent implements OnInit {

  constructor(public bsModalRef: BsModalRef) { }

  ngOnInit() {
  }

  public action(value: boolean) {
    this.bsModalRef.hide();
    this.bsModalRef.content.subject.next(value);
    this.bsModalRef.content.subject.complete();
  }

}
