import { Component, OnInit, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap';

// Services
import { AuthenticationService } from '../../authentication/authentication.service';
import { NotificationsService } from '../../notifications/notifications.service';
import { PagesService } from '../pages.service';

// Models
import { UserModel } from '../../models/user.model';
import { PageModel } from '../../models/page.model';

@Component({
  selector: 'app-page-thumbnail',
  templateUrl: './page-thumbnail.component.html',
  styleUrls: ['./page-thumbnail.component.css']
})
export class PageThumbnailComponent implements OnInit {

  @Input() pageItem;
  @Input() selectedItem;
  @Output() onDelete = new EventEmitter<Object>();
  @Output() onItemClick = new EventEmitter<PageModel>();
  @ViewChild('deleteModal') deleteModal: ModalDirective;
  @ViewChild('publishModal') publishModal: ModalDirective;
  @ViewChild('shareModal') shareModal: ModalDirective;
  
  public clicked: boolean = false;
  public member: UserModel = null;
  private _publishStatusChangeEnabled: boolean = false;

  constructor(private _router: Router,
    private _notifications: NotificationsService,
    private _authentication: AuthenticationService,
    private _pageService: PagesService) { }

  ngOnInit() {
    this.member = this._authentication.member;
  }

  public editPublishedStatus() {
    this._pageService.editPage(<PageModel>{
      published: (this.pageItem.published !== null) ? '' : new Date().toJSON()
    }, this.pageItem._id).subscribe(
      (res) => {
        this.publishModal.hide();
        this._publishStatusChangeEnabled = true;
      },
      (error) => { console.log(JSON.stringify(error)); }
    );
  }

  public onPublishModalHidden() {
    if (this._publishStatusChangeEnabled) {
      (this.pageItem.published !== null) ? this.pageItem.published = null : this.pageItem.published = new Date().toJSON();
      this._notifications.message.emit((this.pageItem.published === null) ? 'Page succesfully unpublished' : 'Page succesfully published');
      if (this.pageItem.published) this.shareModal.show();
      this._publishStatusChangeEnabled = false;
    }
  }

  public edit() {
    this._router.navigate([`/page-profile/${this.pageItem._id}`]);
  }

  public onItemDelete() {
    this.onDelete.emit(this.pageItem);
    this.deleteModal.hide();
  }

  public onItemSelect() {
    this.clicked = true;
    this.onItemClick.emit(this.pageItem);
  }

}
