import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { tap, map } from 'rxjs/operators';
import { of } from 'rxjs/observable/of';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import 'rxjs/add/observable/from';
import 'rxjs/Rx';

// Models
import { CredentialsModel } from '../models/credentials.model';
import { UserModel } from '../models/user.model';


@Injectable()
export class AuthenticationService {

  public member: UserModel = null;

  constructor(private _http: HttpClient,
    private _router: Router) { }

  private _setToken(token: string) {
    localStorage.setItem('accessToken', token);
  }

  public getToken() {
    return localStorage.getItem('accessToken');
  }

  public loadMember() {
    return this.member
      ? of(this.member) 
      : this._http.get('user').pipe(tap((data: any) => {
        this.member = data;
      }));
  }

  public updateUser(user: UserModel) {
    return this._http.put('user', user);
  }

  public refreshMember() {
    return this._http.get('user').pipe(tap((data: any) => {
      this.member = data;
    }));
  }

  public signIn(credentials: CredentialsModel) {
    return this._http.post(`authentication/sign-in`, credentials)
      .pipe(tap((data: any) => {
        if (data.token) {
          this._setToken(data.token);
        }
        this._router.navigateByUrl('/posts');
      }));
  }

  public checkSignUpAvailability() {
    return this._http.head(`authentication/sign-up`);
  }

  public signUp(user: UserModel) {
    return this._http.post(`authentication/sign-up`, user);
  }

  public signUpWithCode(user: UserModel, code: string) {
    return this._http.post(`authentication/sign-up/${code}`, user);
  }

  public signOut() {
    this.member = null;
    localStorage.removeItem('accessToken');
    this._router.navigateByUrl('/sign-in');
  }

  public isAuthenticated() {
    const token = localStorage.getItem('accessToken');
    return token && token !== '' ? this.loadMember() : of(false);
  }

  public checkIfUsernameExists(username: string) {
    return this._http.head(`user/${username}`)
      .map(() => {
        return true;
      }).catch(() => {
        return Observable.from([false]);
      });
  }
}
