import { CanActivate, Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs/Observable";
import { of } from 'rxjs/observable/of';
import { catchError } from "rxjs/operators/catchError";
import 'rxjs/Rx';

//Service
import { AuthenticationService } from "./authentication.service";

@Injectable()
export class IsNotAuthenticatedGuard implements CanActivate {

  constructor(private _router: Router,
    private _authentication: AuthenticationService) { }

  canActivate(): Observable<boolean>|boolean {
    return this._authentication.isAuthenticated()
      .pipe(catchError(error => of(false))).map(res => {
        if (res !== false) {
          this._router.navigate(['/posts']);
          return false;
        } else {
          return true;
        }
      });
  }

}
