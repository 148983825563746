import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { PageModel } from "../models/page.model";

@Injectable()
export class PagesService {

  constructor(private _http: HttpClient) { }

  public listPages(tag = null) {
    let query: string = tag ? `tag=${tag}` : '';
    return this._http.get(`pages?${query}`);
  }

  public getPage(id) {
    return this._http.get(`pages/${id}`);
  }

  public createPage() {
    return this._http.post('pages', null);
  }

  public editPage(page: PageModel, id: string) {
    return this._http.put(`pages/${id}`, page);
  }

  public deletePage(id: string) {
    return this._http.delete(`pages/${id}`);
  }

  public checkIfTokenExists(token: string) {
    return this._http.head(`pages/${token}`);
  }
}