import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { tap } from "rxjs/operators";
import { environment } from "../../environments/environment";

@Injectable()
export class MediaService {

    public url = `${environment.apiUrl}/media`;

    private _apiToken: string = 'media';
    private _entityTypes: any = {
        Authors: 'Authors',
        Posts: 'Posts',
        Pages: 'Pages',
        Notifications: 'Notifications'
    };

    constructor(private _http: HttpClient) {  }

    private _buildContext(entityType: string = null, id: string = null) {
        if (!entityType || Object.keys(this._entityTypes).indexOf(entityType) === -1) return '';
        return `/${entityType + (id ? `/${id}` : '')}`;
    }

    public listAuthorFiles(id: string = null, startFrom: number = 0) {
        const context: string = this._buildContext(this._entityTypes.Authors, id);
        return this._http.get(`${this._apiToken}${context}?startFrom=${startFrom}&count=10`);
    }

    public listPostFiles(id: string = null, startFrom: number = 0) {
        const context: string = this._buildContext(this._entityTypes.Posts, id);
        return this._http.get(`${this._apiToken}${context}?startFrom=${startFrom}&count=10`);
    }

    public listPageFiles(id: string = null, startFrom: number = 0) {
        const context: string = this._buildContext(this._entityTypes.Pages, id);
        return this._http.get(`${this._apiToken}${context}?startFrom=${startFrom}&count=10`);
    }

    public listNotificationFiles(id: string = null, startFrom: number = 0) {
        const context: string = this._buildContext(this._entityTypes.Notifications, id);
        return this._http.get(`${this._apiToken}${context}?startFrom=${startFrom}&count=10`);
    }

    public uploadAuthorFile(fileToUpload: File, id: string = null) {
        const context: string = this._buildContext(this._entityTypes.Authors, id),
            formData: FormData = new FormData();
        formData.append('upload', fileToUpload, fileToUpload.name);
        return this._http.post(`${this._apiToken}${context}`, formData);
    }

    public uploadPostFile(fileToUpload: File, id: string = null) {
        const context: string = this._buildContext(this._entityTypes.Posts, id),
            formData: FormData = new FormData();
        formData.append('upload', fileToUpload, fileToUpload.name);
        return this._http.post(`${this._apiToken}${context}`, formData);
    }

    public uploadPageFile(fileToUpload: File, id: string = null) {
        const context: string = this._buildContext(this._entityTypes.Pages, id),
            formData: FormData = new FormData();
        formData.append('upload', fileToUpload, fileToUpload.name);
        return this._http.post(`${this._apiToken}${context}`, formData);
    }

    public uploadNotificationFile(fileToUpload: File, id: string = null) {
        const context: string = this._buildContext(this._entityTypes.Notifications, id),
            formData: FormData = new FormData();
        formData.append('upload', fileToUpload, fileToUpload.name);
        return this._http.post(`${this._apiToken}${context}`, formData);
    }

    public listImageUsage(id: string) {
        return this._http.put(`media/${id}`, null);
    }

    public deleteImage(id: string) {
        return this._http.delete(`media/${id}`);
    }

    public copyToClipboard(textToCopy: string) {
        let selBox = document.createElement('textarea');
        selBox.style.position = 'fixed';
        selBox.style.left = '0';
        selBox.style.top = '0';
        selBox.style.opacity = '0';
        selBox.value = `${this.url}/${textToCopy}`;
        document.body.appendChild(selBox);
        selBox.focus();
        selBox.select();
        document.execCommand('copy');
        document.body.removeChild(selBox);
    }
}