import { HTTP_INTERCEPTORS, HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Router } from '@angular/router';
import 'rxjs/add/operator/do';
import { Injectable } from '@angular/core';

// Environment
import { environment } from '../../environments/environment';

// Services
import { AuthenticationService } from './authentication.service';

@Injectable()
export class AuthenticationInterceptor implements HttpInterceptor {

  constructor(private _authentication: AuthenticationService,
    private _router: Router) { }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    const headers: any = {
      'Content-Type': 'application/json'
    };

    if (!req.url.match(new RegExp('authentication\/.+'))) {
      headers['x-access-token'] = `${this._authentication.getToken()}`;
    }

    /**
     *  [https://github.com/angular/angular/issues/11819]
     *  > Angular's http needs to generate a content type with boundary when the payload is a FormData object (multipart form).
     *  > Angular won't generate the content type if the content type is already set.
     *  > This is the reason I set an empty content type.
     */
    if (req.url.match(new RegExp('media.*')) && req.method === 'POST') {
      delete headers['Content-Type'];
    }

    req = req.clone({
      url: `${environment.apiUrl}/api/v1/${req.url}`,
      setHeaders: headers
    });

    return next.handle(req).do(
      (event: HttpEvent<any>) => { },
      (error: any) => {
        if (error instanceof HttpErrorResponse) {
          if ([401, 403].indexOf((<HttpErrorResponse>error).status) !== -1) {
            console.log(`[AuthenticationInterceptor] :: Intercepted ${(<HttpErrorResponse>error).status}
              error - ${JSON.stringify(error)} | ${req.url}`);
            this._authentication.signOut();
          }
        }
      }
    );
  }
}
