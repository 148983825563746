import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MarkdownModule } from 'ngx-markdown';
import { NgxDnDModule } from '@swimlane/ngx-dnd';
import { ModalModule } from 'ngx-bootstrap';
import { TypeaheadModule, BsDropdownModule, BsDatepickerModule } from 'ngx-bootstrap';
import { TextareaAutosizeModule } from 'ngx-textarea-autosize';
import { ContenteditableModule } from 'ng-contenteditable';
import { PapaParseModule } from 'ngx-papaparse';

// Routing
import { routes } from './app.routing';

// Components
import { AppComponent } from './app.component';
import { AccountsComponent } from './accounts/accounts.component';
import { AuthenticationComponent } from './authentication/authentication.component';
import { SignInComponent } from './authentication/sign-in/sign-in.component';
import { SignUpComponent } from './authentication/sign-up/sign-up.component';
import { PostsComponent } from './posts/posts.component';
import { PostThumbnailComponent } from './posts/post-thumbnail/post-thumbnail.component';
import { PostProfileComponent } from './posts/post-profile/post-profile.component';
import { PostPreviewComponent } from './posts/post-preview/post-preview.component';
import { AuthorsComponent } from './authors/authors.component';
import { AuthorThumbnailComponent } from './authors/author-thumbnail/author-thumbnail.component';
import { AuthorPreviewComponent } from './authors/author-preview/author-preview.component';
import { AuthorProfileComponent } from './authors/author-profile/author-profile.component';
import { NavigationComponent } from './navigation/navigation.component';
import { SettingsComponent } from './settings/settings.component';
import { LayoutComponent } from './layout/layout.component';
import { PopupComponent } from './popup/popup.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { NotificationsComponent } from './notifications/notifications.component';
import { NotificationThumbnailComponent } from './notifications/notification-thumbnail/notification-thumbnail.component';
import { PagesComponent } from './pages/pages.component';
import { PagePreviewComponent } from './pages/page-preview/page-preview.component';
import { PageProfileComponent } from './pages/page-profile/page-profile.component';
import { PageThumbnailComponent } from './pages/page-thumbnail/page-thumbnail.component';
import { NavigationThumbnailComponent } from './navigation/navigation-thumbnail/navigation-thumbnail.component';
import { InvitationThumbnailComponent } from './authors/invitations/invitation-thumbnail/invitation-thumbnail.component';
import { InvitationsComponent } from './authors/invitations/invitations.component';
import { MessagesComponent } from './messages/messages.component';
import { LeavePageComponent } from './leave-page/leave-page.component';

// Http Interceptors
import { AuthenticationInterceptor } from './authentication/authentication.interceptor';

// Services
import { AuthenticationService } from './authentication/authentication.service';
import { AccountsService } from './accounts/accounts.service';
import { PostsService } from './posts/posts.service';
import { NavigationService } from './navigation/navigation.service';
import { AuthorsService } from './authors/authors.service';
import { NotificationsService } from './notifications/notifications.service';
import { SettingsService } from './settings/settings.service';
import { MediaService } from './media/media.service';
import { PagesService } from './pages/pages.service';
import { MessagesService } from './messages/messages.service';

// Guard
import { IsAuthenticatedGuard } from './authentication/is-authenticated.guard';
import { IsNotAuthenticatedGuard } from './authentication/is-not-authenticated.guard';
import { SignUpAvailabilityGuard } from './authentication/sign-up-availability.guard';
import { MemberResolve } from './authentication/member.resolve';
import { CanDeactivateGuard } from './can-deactiate-guard';

// Directives
import { EqualValidator } from './authentication/equalValidator.directive';
import { UniqueUsernameValidatorDirective } from './authentication/uniqueUsernameValidator.directive';

// Pipe
import { SafePipe } from './pages/safe.pipe';

@NgModule({
  declarations: [
    AppComponent,
    AccountsComponent,
    AuthenticationComponent,
    SignInComponent,
    SignUpComponent,
    PostsComponent,
    AuthorsComponent,
    AuthorThumbnailComponent,
    AuthorPreviewComponent,
    AuthorProfileComponent,
    NavigationComponent,
    SettingsComponent,
    LayoutComponent,
    SidebarComponent,
    PostThumbnailComponent,
    PostProfileComponent,
    PopupComponent,
    PostPreviewComponent,
    NotificationsComponent,
    NotificationThumbnailComponent,
    PagesComponent,
    PagePreviewComponent,
    PageProfileComponent,
    PageThumbnailComponent,
    SafePipe,
    NavigationThumbnailComponent,
    InvitationThumbnailComponent,
    InvitationsComponent,
    EqualValidator,
    UniqueUsernameValidatorDirective,
    MessagesComponent,
    LeavePageComponent
  ],
  // entryComponents: [
  //   LeavePageComponent,
  // ],
  imports: [
    BrowserModule,
    RouterModule.forRoot(routes, { useHash: true , onSameUrlNavigation: 'reload' }),
    HttpClientModule,
    FormsModule,
    MarkdownModule.forRoot(),
    TypeaheadModule.forRoot(),
    BsDropdownModule.forRoot(),
    BsDatepickerModule.forRoot(),
    NgxDnDModule,
    ModalModule.forRoot(),
    TextareaAutosizeModule,
    ContenteditableModule,
    PapaParseModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptor,
      multi: true
    },
    MediaService,
    AuthenticationService,
    AccountsService,
    PostsService,
    PagesService,
    NavigationService,
    AuthorsService,
    NotificationsService,
    SettingsService,
    MessagesService,
    IsAuthenticatedGuard,
    CanDeactivateGuard,
    IsNotAuthenticatedGuard,
    SignUpAvailabilityGuard,
    MemberResolve
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
