import { Component, OnInit, EventEmitter, Output, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { MarkdownService } from 'ngx-markdown';
import { ModalDirective } from 'ngx-bootstrap';
import { Subject } from 'rxjs/Subject';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

// Environment
import { environment } from '../../../environments/environment';

// Models
import { PostModel } from '../../models/post.model';
import { MediaItemModel } from '../../models/mediaItem.model';
import { ListOnRemoveImage } from '../../models/listOnRemoveImage.model';

// Services
import { PostsService } from '../posts.service';
import { NotificationsService } from '../../notifications/notifications.service';
import { MediaService } from '../../media/media.service';

@Component({
  selector: 'app-post-profile',
  templateUrl: './post-profile.component.html',
  styleUrls: ['./post-profile.component.css']
})
export class PostProfileComponent implements OnInit {

  public post: PostModel = null;
  public imageList: Array<MediaItemModel> = <Array<MediaItemModel>>[];
  public loadMoreEnable = true;
  public selectedId: string = null;
  public listsOnRemoveImage: ListOnRemoveImage = null;
  public tokenAlreadyExists = false;
  public summaryVisibility = false;
  public imageId: string = '';
  public isProfilePicture: boolean = false;
  public tags: string = '';

  public contentChanged: Subject<string> = new Subject<string>();
  public previewFrameUrl: string;

  private _fileToUpload: File = null;
  private _loadImagesFrom = 0;
  private _imageCount = 10;
  private _postToken: string = null;
  private _isNewPost: boolean = true;

  @Output() onShowNotification: EventEmitter<string> = new EventEmitter<string>();
  @ViewChild('deleteModal') deleteModal: ModalDirective;
  @ViewChild('publishModal') publishModal: ModalDirective;
  @ViewChild('shareModal') shareModal: ModalDirective;
  @ViewChild('postForm') form: any;
  @ViewChild('previewForm') previewForm;

  constructor(private _postService: PostsService,
    private _router: Router,
    private _routeActivated: ActivatedRoute,
    private _markdownService: MarkdownService,
    private _notifications: NotificationsService,
    public _media: MediaService) {
    this.contentChanged.pipe(
      debounceTime(1000),
      distinctUntilChanged())
      .subscribe(model => {
        this._generatePreview();
      });
  }

  ngOnInit() {
    this._routeActivated.params.subscribe(params => {
      if (params['id'] === 'new-post') {
        this._postService.createPost().subscribe(
          (res) => {
            this.post = <PostModel>res;
            this.getImages();
          },
          (err) => { console.log(JSON.stringify(err)); }
        )
      }
      else this.initPost(params['id']);
    });
  }

  public initPost(id) {
    this._postService.getPostsItem(id).subscribe(
      (res) => {
        this._isNewPost = false;
        this.post = <PostModel>res;
        this._postToken = this.post.token;
        if (this.post.tags) {
          this.post.tags.forEach(element => {
            this.tags += `${element} `;
          });
        }

        // Load Images
        this.getImages();
        this.imageId = (this.post.image) ? this.post.image._id : '';

        // Load Preview
        this.previewFrameUrl = `${environment.clientUrl}/${environment.adminPreviewToken}/Post/${this.post.token}`;
        setTimeout(() => { this._generatePreview(); }, 500);
      },
      (err) => { this.post = <PostModel>{} }
    );
  }

  public savePost() {
    this.post.localization = {};
    
    if (this.post.published === null) { this.post.published = ''; }
    this._postService.editPost(this._buildChanges(), this.post._id).subscribe(
      (res) => {
        this._notifications.message.emit(this._isNewPost ? 'Post created' : 'Changes saved successfully');
        this.form.form.pristine = true;
      },
      (error) => { JSON.stringify(error); }
    );
  }

  public editPublishedStatus() {
    (this.post.published !== null) ? this.post.published = null : this.post.published = new Date().toJSON();
    if (this.post.published) { this.shareModal.show(); }
  }

  public uploadFile(event) {
    if (!event.target.files.length) { return false; }
    this._fileToUpload = event.target.files.item(0);
    this._media.uploadPostFile(this._fileToUpload, this.post._id).subscribe(
      (res) => {
        this.getImages();
      },
      (err) => { console.log(JSON.stringify(err)); }
    );
  }

  public getImages(loadMore: boolean = false) {
    if (loadMore) { this._loadImagesFrom += this._imageCount; }
    else {
      this.imageList = [];
      this._loadImagesFrom = 0;
    }
    this._media.listPostFiles(this.post._id, this._loadImagesFrom).subscribe(
      (res: Array<MediaItemModel>) => {
        this.imageList = this.imageList.concat(<Array<MediaItemModel>>res);
        (res.length < this._imageCount) ? this.loadMoreEnable = false : this.loadMoreEnable = true;
      },
      (err) => { console.log(JSON.stringify(err)); }
    );
  }

  public openDeleteModal(id: string) {
    (this.imageId === id) ? this.isProfilePicture = true : this.isProfilePicture = false;
    this._media.listImageUsage(id).subscribe(
      (res) => {
        this.deleteModal.show();
        this.listsOnRemoveImage = <ListOnRemoveImage>res;
      },
      (err) => { console.log(JSON.stringify(err)); }
    );
    this.selectedId = id;
  }

  public removeImage() {
    if (this.selectedId === this.imageId) {
      this.imageId = '';
      this._postService.editPost(<PostModel>{image: ''}, this.post._id).subscribe(
        (res) => {
          this._notifications.message.emit('Profile Picture removed');
        },
        (error) => { JSON.stringify(error); }
      );
    }
    this._media.deleteImage(this.selectedId).subscribe(
      (res) => {
        this.imageList.splice(0, this.imageList.length);
        this.getImages();
        this.deleteModal.hide();
      },
      (err) => { console.log(JSON.stringify(err)); }
    );
  }

  public setProfilePicture(id: string) {
    this.imageId = id;
    this.getImages();
  }

  public unsetAsProfilePicture() {
    this.imageId = '';
  }

  public autoCompleteUrl() {
    let token = this.post.name.toLowerCase();
    token = token.trim().replace(/\s\s+/g, ' ').replace(/\s/g, '-');
    this.post.token = token;
    this.checkIfTokenExists();
  }

  public checkIfTokenExists() {
    if (this.post.token !== this._postToken) {
      this._postService.checkIfTokenExists(this.post.token).subscribe(
        (res) => { this.tokenAlreadyExists = true; },
        (err) => { this.tokenAlreadyExists = false; }
      );
    }
  }

  public createTagsArray() {
    if (this.tags.length > 0) {
      this.tags = this.tags.toLowerCase().trim().replace(/\s\s+/g, ' ').replace(/\s/g, ' ');
      this.post.tags.splice(0,this.post.tags.length);
      var tempArray = this.tags.split(' ');
      tempArray.forEach(element => {
        this.post.tags.push(element);
      });
    }
    else this.post.tags.splice(0,this.post.tags.length);
  }

  public isSaveDisabled() {
    if (this.post.name && this.post.token) { return false; }
    return true;
  }

  public canDeactivate() {
    if (!this.form.pristine) {
      return window.confirm('Are you sure you want to leave this page without saving your changes first?');
    }
    return true;
  }

  public copyToClipboard(textToCopy: string) {
    this._media.copyToClipboard(textToCopy);
    this._notifications.message.emit('Copied to clipboard');
  }

  private _buildChanges() {
    return <PostModel>{
      name: this.post.name,
      summary: this.post.summary,
      content: this.post.content,
      published: this.post.published,
      image: this.imageId,
      token: this.post.token,
      tags: this.post.tags ? this.post.tags : [],
      localization: this.post.localization
    };
  }

  private _generatePreview() {
    let previewForm = this.previewForm.nativeElement;
    previewForm.firstElementChild.value = this._markdownService.compile(this.post.content);
    previewForm.submit();
  }

  public generatePreview(event) {
    this.contentChanged.next(this.post.content);
  }
}
