import { Component, OnInit } from '@angular/core';

// Services
import { AuthenticationService } from '../authentication/authentication.service';
import { MediaService } from '../media/media.service';

// Models
import { UserModel } from '../models/user.model';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  public avatar: string = null;

  constructor(public _authentication: AuthenticationService,
    public _media: MediaService) { }

  ngOnInit() {
    this._authentication.member = this._authentication.member;
    this.buildAvatar();
  }

  private buildAvatar() {
    this.avatar = (this._authentication.member.name || this._authentication.member.username || this._authentication.member.email.substring(0, this._authentication.member.email.lastIndexOf("@")))
      .split(/[\s,._]+/)
      .reduce((response, word) => response += word.slice(0, 1), '')
      .substr(0, 2);
  }

  public signOut() {
    this._authentication.signOut();
  }
}
