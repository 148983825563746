import { Component, Input, ViewChild } from '@angular/core';
import { MarkdownService } from 'ngx-markdown';

// Environment
import { environment } from '../../../environments/environment';

// Models
import { PostModel } from '../../models/post.model';

@Component({
  selector: 'app-post-preview',
  templateUrl: './post-preview.component.html',
  styleUrls: ['./post-preview.component.css']
})
export class PostPreviewComponent {

  @Input('model') model: PostModel = null;

  public frameUrl: string;
  
  @ViewChild('previewForm') previewForm;

  constructor(private _markdown: MarkdownService) { }

  ngOnChanges() {
    if (!this.model) return;

    // Load Preview
    this.frameUrl = `${environment.clientUrl}/${environment.adminPreviewToken}/Post/${this.model.token}`;
    setTimeout(() => { this._generatePreview(); }, 500);
  };

  private _generatePreview() {
    let previewForm = this.previewForm.nativeElement;
    previewForm.firstElementChild.value = this._markdown.compile(this.model.content);
    previewForm.submit();
  }
}
