import { Component, OnInit, Input, Output, EventEmitter, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap';

// Models
import { AuthorModel } from '../../models/author.model';
import { UserModel } from '../../models/user.model';

// Services
import { AuthorsService } from '../authors.service';
import { AuthenticationService } from '../../authentication/authentication.service';
import { NotificationsService } from '../../notifications/notifications.service';
import { MediaService } from '../../media/media.service';

@Component({
  selector: 'app-author-thumbnail',
  templateUrl: './author-thumbnail.component.html',
  styleUrls: ['./author-thumbnail.component.css']
})
export class AuthorThumbnailComponent implements OnInit {

  @Input() author;
  @Input() selectedItem;
  @Output() selected = new EventEmitter<AuthorModel>();
  @Output() deleted = new EventEmitter<AuthorModel>();
  @ViewChild('deleteModal') deleteModal: ModalDirective;
  public member: UserModel = null;

  constructor(private _router: Router, 
    private _authors: AuthorsService,
    private _notifications: NotificationsService,
    private _authentication: AuthenticationService,
    public _media: MediaService) { }

  ngOnInit() {
    this.member = this._authentication.member;
  }

  public onDelete() {
    this._authors.removeAuthor(this.author._id).subscribe(
      (res) => {
        this._notifications.message.emit('Author was deleted successfully');
        this.hideDeleteModal();
        this.deleted.emit();
      },
      (err) => { console.log(JSON.stringify(err)); }
    );
  }

  public preview() {
    
    this.selected.emit(this.author);
  }

  public edit() {
    this._router.navigate([`/author-profile/${this.author.username}`]);
  }
  
  public hideDeleteModal(): void {
    this.deleteModal.hide();
  }

}
