import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

// Services
import { PagesService } from './pages.service';
import { NotificationsService } from '../notifications/notifications.service';

// Models
import { PageModel } from "../models/page.model";

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styleUrls: ['./pages.component.css']
})
export class PagesComponent implements OnInit {

  public selectedPageItem: PageModel = null;
  public pageList: Array<PageModel> = <Array<PageModel>>[];
  public pageSearch: string = null;

  constructor(private _pageService: PagesService,
    private _notification: NotificationsService,
    private _router: Router) {
    this._router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        this.pageSearch = '';
        this.getPagesList();
      }
    });
  }

  ngOnInit() {
    this.getPagesList();
  }

  public getPagesList(tag = null) {
    this._pageService.listPages(tag).subscribe(
      (res) => { this.pageList = <Array<PageModel>>res; },
      (err) => { console.log(JSON.stringify(err)); }
    );
  }

  public deletePage(pageItem: PageModel) {
    this._pageService.deletePage(pageItem._id).subscribe(
      (res) => { 
        this._notification.message.emit("Page was deleted successfully");
        this.getPagesList(); 
      },
      (err) => { console.log(JSON.stringify(err)); }
    );
  }

  public pagePreview(postItem: PageModel) {
    this.selectedPageItem = postItem;
  }

  public createPage() {
    this._router.navigate([`/page-profile/new-page`]);
  }

  public search(event) {
    this.pageSearch = event.target.value
    this.getPagesList(this.pageSearch);
  }

}
