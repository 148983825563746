import { Component, Input } from '@angular/core';

// Services
import { MediaService } from '../../media/media.service';

@Component({
  selector: 'app-author-preview',
  templateUrl: './author-preview.component.html',
  styleUrls: ['./author-preview.component.css']
})
export class AuthorPreviewComponent {

  @Input() selectedAuthor;
  public showPreview: boolean = false;

  constructor(public _media: MediaService) { }

  ngOnChanges() {
    if (this.selectedAuthor.length > 0) this.showPreview = true;
  };
}
