import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective, TypeaheadModule } from 'ngx-bootstrap';
import { BsDatepickerConfig } from 'ngx-bootstrap';
import { BsDatepickerViewMode } from 'ngx-bootstrap/datepicker/ngx-bootstrap-datepicker';

// Models
import { SocialLinkModel } from '../models/socialLink.model';
import { SettingsModel } from '../models/settings.model';
import { UserModel } from '../models/user.model';

// Services
import { SettingsService } from './settings.service';
import { NotificationsService } from '../notifications/notifications.service';
import { AuthenticationService } from '../authentication/authentication.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {

  public member: UserModel = null;
  public settings: SettingsModel = null;
  public platformAlreadyExists: boolean = false;
  public socialPlatforms: string[] = [
    'facebook',
    'twitter',
    'google-plus',
    'pinterest',
    'instagram'
  ];
  public dateTimePickerConfig: Partial<BsDatepickerConfig> = {
    containerClass: 'theme-default',
    dateInputFormat: 'YYYY-MM-DD',
    showWeekNumbers: false
  };
  public editionPickerConfig: Partial<BsDatepickerConfig> = {
    containerClass: 'theme-default',
    dateInputFormat: 'YYYY',
    minMode: <BsDatepickerViewMode>'year',
    minDate: new Date()
  };

  constructor(private _settings: SettingsService,
    private _notifications: NotificationsService,
    private _authentication: AuthenticationService) { }

  ngOnInit() {
    this.member = this._authentication.member;
    this._fetch();
  }

  private _fetch() {
    this._settings.fetch().subscribe(
      (res) => {
        this.settings = <SettingsModel>res;

        // Set default value for edition (2020)
        this.settings.edition = this.settings.edition || 2020;
      },
      (err) => { 
        this._notifications.message.emit(`There was an unexpected error. Please contact Tech Support.`);
        console.log(err)
      }
    )
  }

  public saveSettings() {
    this.settings.socialLinks = this.settings.socialLinks.filter(value => Object.keys(value).length !== 0);
    this._settings.update(this._buildChanges()).subscribe(
      (res) => {
        this._notifications.message.emit('Settings saved.');
        this._fetch();
      },
      (err) => { 
        this._notifications.message.emit(`There was an unexpected error. Please contact Tech Support.`);
        console.log(err)
      }
    );
  }

  private _buildChanges() {
    return <SettingsModel>{
      appName: this.settings.appName,
      socialLinks: this.settings.socialLinks,
      earlyBirdDate: this.settings.earlyBirdDate,
      registrationDeadline: this.settings.registrationDeadline,
      edition: this.settings.edition
    }
  }

  public deleteSocialItem(index) {
    this.settings.socialLinks.splice(index, 1);
    // Check for social media duplicates
    this.platformAlreadyExists = false;
    this.settings.socialLinks.forEach(xElement => {
      this.settings.socialLinks.forEach(yElement => {
        if (xElement.platform === yElement.platform) console.log(xElement, yElement);
      })
    });
  }

  public addNewSocialItem() {
    this.settings.socialLinks.push(<SocialLinkModel>{});
  }

  public isSaveEnabled() {
    var enable = false;
    this.settings.socialLinks.forEach(element => {
      if (element.platform && !element.url || (!element.platform && element.url)) enable = true;
    });
    return enable;
  }

  public typeaheadOnSelect(event, index) {
    for (let i = 0; i < this.settings.socialLinks.length; ++i) {
      if (this.settings.socialLinks[i].platform === event.value && i !== index) this.platformAlreadyExists = true;
    }
  }

  public onEditionChange($event) {
    if (this.settings && $event) this.settings.edition = $event.getFullYear();
  }

  public onEarlyBirdDateChange($event) {
    $event.setHours(23, 59, 59, 999);
    if (this.settings && $event) this.settings.earlyBirdDate = $event;
  }

  public onRegistrationDeadlineChange($event) {
    $event.setHours(23, 59, 59, 999);
    if (this.settings && $event) this.settings.registrationDeadline = $event;
  }

  public migrate() {
    this._settings.migrate(this.settings.edition).subscribe(
      () => { this._notifications.message.emit(`Migrated to the new ${this.settings.edition} Conference.`); },
      (err) => {
        this._notifications.message.emit(`There was an unexpected error. Please contact Tech Support.`);
        console.log(err);
      }
    );
  }
}
