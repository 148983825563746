import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';

// Services
import { AuthorsService } from '../../authors.service';
import { NotificationsService } from '../../../notifications/notifications.service';

// Models
import { InvitationModel } from '../../../models/author.model';
import { Route, Router } from '@angular/router';
import { windowWhen } from 'rxjs/operator/windowWhen';

@Component({
  selector: 'app-invitation-thumbnail',
  templateUrl: './invitation-thumbnail.component.html',
  styleUrls: ['./invitation-thumbnail.component.css']
})
export class InvitationThumbnailComponent implements OnInit {

  @Input() invitation;
  @Output() onReloadInvitationList = new EventEmitter<InvitationModel>();

  public dateTime: string;
  public date: string; 

  constructor(private _authors: AuthorsService,
    private _notifications: NotificationsService,
    private _router: Router) { }

  ngOnInit() {
    this.invitation.date = moment(new Date(this.invitation.date)).format('hh:mm A [at] L');
  }

  public deleteInvitation() {
    this._authors.deleteInvitation(this.invitation.email).subscribe(
      (res) => {
        this._notifications.message.emit('Invitation was successfully deleted');
        this.onReloadInvitationList.emit(this.invitation);
      },
      (err) => { console.log(JSON.stringify(err)); }
    );
  }

  public resendInvitation() {
    this._authors.inviteUser(this.invitation.email).subscribe(
      (res) => { 
        this._notifications.message.emit('Invitation was successfully resent');
        this.onReloadInvitationList.emit(this.invitation);
      },
      (err) => { console.log(JSON.stringify(err)); }
    );
  }

  public copyLink() {
    let selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = `https://${window.location.host}/sign-up/${this.invitation.code}`;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

}
