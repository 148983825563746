import { Routes } from '@angular/router';

// Components
import { SignInComponent } from './authentication/sign-in/sign-in.component';
import { SignUpComponent } from './authentication/sign-up/sign-up.component';
import { PostsComponent } from './posts/posts.component';
import { PostProfileComponent } from './posts/post-profile/post-profile.component';
import { AuthorsComponent } from './authors/authors.component';
import { AuthorProfileComponent } from './authors/author-profile/author-profile.component';
import { NavigationComponent } from './navigation/navigation.component';
import { SettingsComponent } from './settings/settings.component';
import { LayoutComponent } from './layout/layout.component';
import { PagesComponent } from './pages/pages.component';
import { PageProfileComponent } from './pages/page-profile/page-profile.component';
import { InvitationsComponent } from './authors/invitations/invitations.component';
import { AccountsComponent } from './accounts/accounts.component';
import { MessagesComponent } from './messages/messages.component';

// Guards
import { IsAuthenticatedGuard } from './authentication/is-authenticated.guard';
import { IsNotAuthenticatedGuard } from './authentication/is-not-authenticated.guard';
import { SignUpAvailabilityGuard } from './authentication/sign-up-availability.guard';
import { MemberResolve } from './authentication/member.resolve';
import { CanDeactivateGuard } from './can-deactiate-guard';

export const routes: Routes = [

  {
    path: 'sign-in',
    component: SignInComponent,
    canActivate: [IsNotAuthenticatedGuard]
  },
  {
    path: 'sign-up',
    component: SignUpComponent,
    canActivate: [IsNotAuthenticatedGuard, SignUpAvailabilityGuard]
  },
  {
    path: 'sign-up/:code',
    component: SignUpComponent,
    canActivate: [IsNotAuthenticatedGuard]
  },
  {
    path: 'pages',
    component: PagesComponent,
    canActivate: [IsAuthenticatedGuard],
    resolve: {member: MemberResolve},
    runGuardsAndResolvers: 'always'
  },
  {
    path: 'page-profile',
    component: PageProfileComponent,
    canActivate: [IsAuthenticatedGuard],
    resolve: {member: MemberResolve}
  },
  {
    path: 'page-profile/:id',
    component: PageProfileComponent,
    canActivate: [IsAuthenticatedGuard],
    // canDeactivate: [CanDeactivateGuard],
    resolve: {member: MemberResolve}
  },
  {
    path: 'posts',
    component: PostsComponent,
    canActivate: [IsAuthenticatedGuard],
    resolve: {member: MemberResolve}
  },
  {
    path: 'post-profile',
    component: PostProfileComponent,
    canActivate: [IsAuthenticatedGuard],
  },
  {
    path: 'post-profile/:id',
    component: PostProfileComponent,
    canActivate: [IsAuthenticatedGuard]
  },
  {
    path: 'authors',
    component: AuthorsComponent,
    canActivate: [IsAuthenticatedGuard],
    resolve: {member: MemberResolve}
  },
  {
    path: 'accounts',
    component: AccountsComponent,
    canActivate: [IsAuthenticatedGuard],
    resolve: {member: MemberResolve}
  },
  {
    path: 'author-profile/:id',
    component: AuthorProfileComponent,
    canActivate: [IsAuthenticatedGuard],
    resolve: {member: MemberResolve}
  },
  {
    path: 'invitations',
    component: InvitationsComponent,
    canActivate: [IsAuthenticatedGuard],
    resolve: {member: MemberResolve}
  },

  {
    path: 'navigation',
    component: NavigationComponent,
    canActivate: [IsAuthenticatedGuard],
    resolve: {member: MemberResolve}
  },
  {
    path: 'layout',
    component: LayoutComponent,
    canActivate: [IsAuthenticatedGuard],
    resolve: {member: MemberResolve}
  },
  {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [IsAuthenticatedGuard],
    resolve: {member: MemberResolve}
  },
  {
    path: 'messages',
    component: MessagesComponent,
    canActivate: [IsAuthenticatedGuard],
    resolve: {member: MemberResolve}
  },
  {
    path: '',
    redirectTo: '/sign-up',
    pathMatch: 'full'
  }
];

