import { Component, OnInit, HostListener, ElementRef } from '@angular/core';

@Component({
  selector: 'app-popup',
  templateUrl: './popup.component.html',
  styleUrls: ['./popup.component.css']
})
export class PopupComponent implements OnInit {

  public visible = false;
  private _elementRef: ElementRef;

  constructor() { }

  ngOnInit() { }

  public show() {
    this.visible = true;
  }

  public hide() {
    this.visible = false;
  }
}
