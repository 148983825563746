import { Component, OnInit } from '@angular/core';
import { NotificationsService } from './notifications.service';

@Component({
  selector: 'app-notifications',
  templateUrl: './notifications.component.html',
  styleUrls: ['./notifications.component.css']
})
export class NotificationsComponent implements OnInit {

  public notificationList: Array<string> = [];

  constructor(private _notifications: NotificationsService) { }

  ngOnInit() {
    this._notifications.message.subscribe(event => {
      this.notificationList.unshift(event);
    });
  }

  public onItemAdd(event) {
    this.notificationList.unshift(event);
  }

  public onItemDelete(event) {
    this.notificationList.splice(-1, 1);
  }

}
