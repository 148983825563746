import { Injectable } from '@angular/core';
import { CanDeactivate } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { FormGroup } from '@angular/forms';
import { Subject } from 'rxjs/Subject';
import { BsModalService } from 'ngx-bootstrap';
import { LeavePageComponent } from './leave-page/leave-page.component';

export interface CanComponentDeactivate {
  form: FormGroup;
}

@Injectable()
export class CanDeactivateGuard implements CanDeactivate<CanComponentDeactivate> {

  constructor(private modalService: BsModalService) {}

  canDeactivate(component: CanComponentDeactivate) {
    if (!component.form.pristine) {
      const subject = new Subject<boolean>();

      const modal = this.modalService.show(LeavePageComponent, {'class': 'modal-dialog-primary'});
      modal.content.subject = subject;

      return subject.asObservable();
    }
    return true;
  }

}