import { Injectable, EventEmitter } from "@angular/core";
import { HttpClient } from "@angular/common/http";

// Models
import { AuthorModel } from "../models/author.model";

@Injectable()
export class AuthorsService {

  constructor(private _http: HttpClient) { }

  public getAuthorsList() {
    return this._http.get('authors');
  }

  public removeAuthor(id: string) {
    return this._http.delete(`user/${id}`);
  }

  public getInvitationList() {
    return this._http.get('authors/invitations');
  }

  public inviteUser(email: string, role: string = 'Author') {
    return this._http.post(`authors/invitations/${email}`, { role: role });
  }

  public deleteInvitation(email) {
    return this._http.delete(`authors/invitations/${email}`)
  }
}
