import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';

// Services
import { AuthenticationService } from './authentication.service';

@Injectable()
export class MemberResolve implements Resolve<any> {

  constructor(private _authentication: AuthenticationService) { }

  resolve() {
    return this._authentication.loadMember();
  }
}
