import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { tap } from 'rxjs/operators';

//Models
import { PostModel } from "../models/post.model";

@Injectable()
export class PostsService {

  constructor(private _http: HttpClient) { }

  public getPostsList() {
    return this._http.get('posts');
  }

  public getPostsItem(id: string) {
    return this._http.get(`posts/${id}`);
  }

  public createPost() {
    return this._http.post('posts', null);
  }

  public editPost(post: PostModel, id: string) {
    return this._http.put(`posts/${id}`, post);
  }

  public deletePost(id: string) {
    return this._http.delete(`posts/${id}`);
  }

  public checkIfTokenExists(token: string) {
    return this._http.head(`posts/${token}`);
  }
}
