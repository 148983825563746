import { Component, OnInit, Input, ViewChild, Output, EventEmitter, TemplateRef } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { ModalDirective } from 'ngx-bootstrap';

//Components
import { PopupComponent } from '../../popup/popup.component';

//Services
import { PostsService } from '../posts.service';
import { NotificationsService } from '../../notifications/notifications.service';
import { AuthenticationService } from '../../authentication/authentication.service';

//Models
import { PostModel } from '../../models/post.model';
import { UserModel } from '../../models/user.model';

@Component({
  selector: 'app-post-thumbnail',
  templateUrl: './post-thumbnail.component.html',
  styleUrls: ['./post-thumbnail.component.css']
})
export class PostThumbnailComponent implements OnInit {

  @Input() postItem;
  @Input() selectedItem;
  @Output() onItemClick = new EventEmitter<PostModel>();
  @Output() onDelete = new EventEmitter<PostModel>();
  @ViewChild('publishModal') publishModal: ModalDirective;
  @ViewChild('shareModal') shareModal: ModalDirective;
  @ViewChild('deleteModal') deleteModal: ModalDirective;
  public member: UserModel = null;

  private _publishStatusChangeEnabled: boolean = false;

  constructor(private _http: HttpClient,
    private _router: Router,
    private _posts: PostsService,
    private _notifications: NotificationsService,
    private _authentication: AuthenticationService) { }

  ngOnInit() {
    this.member = this._authentication.member;
  }

  public edit() {
    this._router.navigate([`/post-profile/${this.postItem._id}`]);
  }

  public editPublishedStatus() {
    this._posts.editPost(<PostModel>{published: (this.postItem.published !== null) ? '' : new Date().toJSON()}, this.postItem._id).subscribe(
      (res) => { 
        this.publishModal.hide();
        this._publishStatusChangeEnabled = true;
      },
      (error) => { console.log(JSON.stringify(error)); }
    );
  }

  public onPublishModalHidden() {
    if (this._publishStatusChangeEnabled) {
      (this.postItem.published !== null) ? this.postItem.published = null : this.postItem.published = new Date().toJSON();
      this._notifications.message.emit((this.postItem.published === null) ? 'Post succesfully unpublished' : 'Post succesfully published');
      if (this.postItem.published) this.shareModal.show();
      this._publishStatusChangeEnabled = false;
    }
  }

  public onItemSelect() {
    this.onItemClick.emit(this.postItem);
  }

  public onItemDelete() {
    this.onDelete.emit(this.postItem);
    this.hideDeleteModal();
  }

  public hideDeleteModal(): void {
    this.deleteModal.hide();
  }
}
