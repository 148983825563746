import { Component, OnInit, ViewChild, HostListener } from '@angular/core';
import { ModalDirective, BsModalService } from 'ngx-bootstrap';
import { TextareaAutosizeModule } from 'ngx-textarea-autosize';
import { Subject } from 'rxjs/Subject';

// Components
import { LeavePageComponent } from '../../leave-page/leave-page.component';

// Models
import { UserModel } from '../../models/user.model';
import { MediaItemModel } from '../../models/mediaItem.model';
import { ListOnRemoveImage } from '../../models/listOnRemoveImage.model';

// Services
import { MediaService } from '../../media/media.service';
import { AuthenticationService } from '../../authentication/authentication.service';
import { NotificationsService } from '../../notifications/notifications.service';

@Component({
  selector: 'app-author-profile',
  templateUrl: './author-profile.component.html',
  styleUrls: ['./author-profile.component.css']
})
export class AuthorProfileComponent implements OnInit {

  public author: UserModel = <UserModel>{};
  public imageList: Array<MediaItemModel> = <Array<MediaItemModel>>[];
  public loadMoreEnable = true;
  public selectedId: string = null;
  public listsOnRemoveImage: ListOnRemoveImage = null;
  public summaryVisibility: boolean = false;
  public popupClose: boolean = false;

  private _fileToUpload: File = null;
  private _imageCount = 10;
  private _loadImagesFrom = 0;

  @ViewChild('deleteModal') deleteModal: ModalDirective;
  @ViewChild('leavePageModal') leavePageModal: ModalDirective;
  @ViewChild('authorForm') form: any;

  constructor(public _media: MediaService,
    private _authentication: AuthenticationService,
    private _notifications: NotificationsService,
    private _modalService: BsModalService
  ) { }

  ngOnInit() {
    this.author = JSON.parse(JSON.stringify(this._authentication.member));
    this.getImages();
  }

  public saveUser() {
    this._authentication.updateUser(this.author).subscribe(
      (res) => { 
        this._notifications.message.emit('Author saved'); 
        this.form.form.pristine = true;
        this._authentication.refreshMember().subscribe();
      },
      (error) => { JSON.stringify(error); }
    );
  }

  public uploadFile(event) {
    if (!event.target.files.length) { return false; }
    this._fileToUpload = event.target.files.item(0);
    this._media.uploadAuthorFile(this._fileToUpload, this.author._id).subscribe(
      (res) => {
        this.imageList.splice(0, this.imageList.length);
        this._loadImagesFrom = 0;
        this.getImages();
      },
      (err) => { console.log(JSON.stringify(err)); }
    );
  }

  public getImages(loadMore: boolean = false) {
    if (loadMore) { this._loadImagesFrom += this._imageCount; }
    this._media.listAuthorFiles(this.author._id, this._loadImagesFrom).subscribe(
      (res: Array<MediaItemModel>) => {
        this.imageList = this.imageList.concat(<Array<MediaItemModel>>res);
        if (res.length < this._imageCount) { this.loadMoreEnable = false; }
      },
      (err) => { console.log(JSON.stringify(err)); }
    );
  }

  public openDeleteModal(id: string) {
    this._media.listImageUsage(id).subscribe(
      (res) => {
        this.deleteModal.show();
        this.listsOnRemoveImage = <ListOnRemoveImage>res;
      },
      (err) => { console.log(JSON.stringify(err)); }
    );
    this.selectedId = id;
  }

  public removeImage() {
    this._media.deleteImage(this.selectedId).subscribe(
      (res) => {
        this.imageList.splice(0, this.imageList.length);
        this.getImages();
        this._loadImagesFrom = 0;
        this.deleteModal.hide();
        if (this.author.image._id === this.selectedId) this.setProfilePicture();
      },
      (err) => { console.log(JSON.stringify(err)); }
    );
  }

  public setProfilePicture(id: string = null) {
    this.author.image = id;
    this._authentication.updateUser(this.author).subscribe(
      (res) => { 
        this._authentication.refreshMember().subscribe(
          (res) => { this.author = JSON.parse(JSON.stringify(res)); },
          (err) => { console.log(JSON.stringify(err)); }
        );
        this._notifications.message.emit('Profile picture saved'); 
      },
      (error) => { JSON.stringify(error); }
    );
  }

  public copyToClipboard(textToCopy: string) {
    this._media.copyToClipboard(textToCopy);
    this._notifications.message.emit('Copied to clipboard');
  }
}
