import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class AccountsService {

    constructor(private _http: HttpClient) { }

    public getClientUsers(search = null, registered = false, currentEdition = false, from, count) {
        return this._http.get(`client-users?${search !== null ? `search=${search}&` : ''}${registered ? 'registered=true&' : ''}${currentEdition ? 'currentEdition=true&' : ''}from=${from}&count=${count}`);
    }
    
    public getExportClientUsers(search = null, registered = false, currentEdition = false) {
        return this._http.get(`client-users/export?${search !== null ? `search=${search}&` : ''}${registered ? 'registered=true&' : ''}${currentEdition ? 'currentEdition=true' : ''}`);
    }

    public editClientUser(id, account) {
        return this._http.put(`client-users/${id}`, account);
    }
}