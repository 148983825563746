import { Directive, forwardRef } from '@angular/core';
import { AbstractControl, AsyncValidator, ValidationErrors, NG_ASYNC_VALIDATORS } from '@angular/forms';
import { Observable, timer, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import 'rxjs/add/observable/of';

// Services
import { AuthenticationService } from './authentication.service';

@Directive({
  selector: '[uniqueUsername][formControlName],[uniqueUsername][formControl],[uniqueUsername][ngModel]',
  providers: [
    { provide: NG_ASYNC_VALIDATORS, useExisting: forwardRef(() => UniqueUsernameValidatorDirective), multi: true }
  ]
})
export class UniqueUsernameValidatorDirective implements AsyncValidator {
  private pristineValue: string = '';

  constructor(private _authentication: AuthenticationService) { }

  validate(control: AbstractControl): Observable<ValidationErrors | null> {
    if (control.pristine) this.pristineValue = control.value;
    if (control.value === this.pristineValue) return of(null);

    return timer(300).pipe(switchMap(() => {
      return this._authentication.checkIfUsernameExists(control.value)
        .map(exists => exists ? { uniqueUsername: true } : null);
    }));

  }
}
