
import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { MarkdownService } from 'ngx-markdown';
import { Subject } from 'rxjs/Subject';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

// Environment
import { environment } from '../../../environments/environment';

// Services
import { NotificationsService } from '../../notifications/notifications.service';
import { PagesService } from '../pages.service';
import { MediaService } from '../../media/media.service';

// Models
import { PageModel } from '../../models/page.model';
import { MediaItemModel } from '../../models/mediaItem.model';
import { ListOnRemoveImage } from '../../models/listOnRemoveImage.model';

@Component({
  selector: 'app-page-profile',
  templateUrl: './page-profile.component.html',
  styleUrls: ['./page-profile.component.css']
})
export class PageProfileComponent implements OnInit {

  @ViewChild('publishModal') publishModal: ModalDirective;
  @ViewChild('shareModal') shareModal: ModalDirective;

  public page: PageModel = null;
  public activeContentWindow: string = 'html';
  public tokenAlreadyExists: boolean = false;
  public imageList: Array<MediaItemModel> = <Array<MediaItemModel>>[];
  public loadMoreEnable: boolean = true;
  public selectedId: string = null;
  public listsOnRemoveImage: ListOnRemoveImage = null;

  public tags: string = '';
  public contentChanged: Subject<string> = new Subject<string>();
  public previewFrameUrl: string;

  private _pageItemToken: string = null;
  private _loadImagesFrom: number = 0;
  private _imageCount: number = 10;
  private _fileToUpload: File = null;
  private _isNewPage: boolean = true;

  @ViewChild('deleteModal') deleteModal: ModalDirective;
  @ViewChild('pageForm') form: any;
  @ViewChild('previewForm') previewForm;

  constructor(private _notifications: NotificationsService,
    private _routeActivated: ActivatedRoute,
    private _pageService: PagesService,
    public _media: MediaService,
    private _markdownService: MarkdownService) {
    this.contentChanged.pipe(
      debounceTime(1000),
      distinctUntilChanged())
      .subscribe(model => {
        this._generatePreview();
      });
  }

  ngOnInit() {
    this.page = <PageModel>{};
    this._routeActivated.params.subscribe(params => {
      if (params['id'] === 'new-page') {
        this._pageService.createPage().subscribe(
          (res) => {
            this.page = <PageModel>res;

            // Load Images!?.. although there should not be any..
            this.getImages();
          },
          (err) => { console.log(JSON.stringify(err)); }
        )
      }
      else {
        if (params['id']) {
          this._pageService.getPage(params['id']).subscribe(
            (res) => {
              this._isNewPage = false;
              
              this.page = <PageModel>res;
              
              // Parse Tags
              if (this.page.tags) {
                this.page.tags.forEach(element => {
                  this.tags += `${element} `;
                });
              }

              // This should not be done like this, or at least we should rename it to "pristineToken" or something similar ..
              this._pageItemToken = this.page.token;

              // Load Images
              this.getImages();

              // Load Preview
              this.previewFrameUrl = `${environment.clientUrl}/${environment.adminPreviewToken}/Page/${this.page.token}`;
              setTimeout(() => { this._generatePreview(); }, 500);
            },
            (err) => { this.page = <PageModel>{} }
          );
        }
      }
    });
  }

  public getImages(loadMore: boolean = false) {
    if (loadMore) { this._loadImagesFrom += this._imageCount; }
    else {
      this.imageList = [];
      this._loadImagesFrom = 0;
    }
    this._media.listPageFiles(this.page._id, this._loadImagesFrom).subscribe(
      (res: Array<MediaItemModel>) => {
        this.imageList = this.imageList.concat(<Array<MediaItemModel>>res);
        this.loadMoreEnable = res.length < this._imageCount ? false : true;
      },
      (err) => { console.log(JSON.stringify(err)); }
    );
  }

  public uploadFile(event) {
    if (!event.target.files.length) { return false; }
    this._fileToUpload = event.target.files.item(0);
    this._media.uploadPageFile(this._fileToUpload, this.page._id).subscribe(
      (res) => {
        this.getImages();
      },
      (err) => { console.log(JSON.stringify(err)); }
    );
  }

  public removeImage() {
    this._media.deleteImage(this.selectedId).subscribe(
      (res) => { 
        this.imageList.splice(0, this.imageList.length);
        this.getImages(); 
        this.deleteModal.hide(); },
      (err) => { console.log(JSON.stringify(err)); }
    );
  }

  public openDeleteModal(id: string) {
    this._media.listImageUsage(id).subscribe(
      (res) => {
        this.deleteModal.show();
        this.listsOnRemoveImage = <ListOnRemoveImage>res;
      },
      (err) => { console.log(JSON.stringify(err)); }
    );
    this.selectedId = id;
  }

  public autoCompleteUrl() {
    let token = this.page.name.toLowerCase();
    token = token.trim().replace(/\s\s+/g, ' ').replace(/\s/g, '-');
    this.page.token = token;
    this.checkIfTokenExists();
  }

  public checkIfTokenExists() {
    if (this._pageItemToken !== this.page.token) {
      this._pageService.checkIfTokenExists(this.page.token).subscribe(
        (res) => { this.tokenAlreadyExists = true; },
        (err) => { this.tokenAlreadyExists = false; }
      );
    } else { this.tokenAlreadyExists = false; }
  }

  public createTagsArray() {
    if (this.tags.length > 0) {
      this.tags = this.tags.toLowerCase().trim().replace(/\s\s+/g, ' ').replace(/\s/g, ' ');
      this.page.tags.splice(0,this.page.tags.length);
      var tempArray = this.tags.split(' ');
      tempArray.forEach(element => {
        this.page.tags.push(element);
      });
    }
    else this.page.tags.splice(0,this.page.tags.length);
  }

  public isSaveDisabled() {
    if (this.page.name && this.page.token) { return false; } else { return true; }
  }

  public savePage() {
    if (!this.tokenAlreadyExists) {
      if (this.page.published === null) { this.page.published = ''; }
      this._pageService.editPage(this._buildChanges(), this.page._id).subscribe(
        (res) => {
          this._notifications.message.emit(this._isNewPage ? 'Page created' : 'Changes saved successfully');
          this.form.form.pristine = true;
        },
        (error) => { JSON.stringify(error); }
      );
    }
  }

  public editPublishedStatus() {
    (this.page.published !== null) ? this.page.published = null : this.page.published = new Date().toJSON();
    if (this.page.published) { this.shareModal.show(); }
  }

  private _buildChanges() {
    return <PageModel>{
      name: this.page.name,
      published: (this.page.published === null) ? '' : this.page.published,
      token: this.page.token,
      tags: this.page.tags,
      content: this.page.content,
      styles: this.page.styles,
      scripts: this.page.scripts,
      localization: this.page.localization
    };
  }

  public copyToClipboard(textToCopy: string) {
    this._media.copyToClipboard(textToCopy);
    this._notifications.message.emit('Copied to clipboard');
  }

  private _generatePreview() {
    let previewForm = this.previewForm.nativeElement;
    previewForm.firstElementChild.value = `${this._markdownService.compile(this.page.content)}
      <style>${this.page.styles}</style>`;
      // Scripts have been left out as it's a XSS vulnerability
    previewForm.submit();
  }

  public generatePreview(event) {
    this.contentChanged.next(this.page.content);
  }
}
