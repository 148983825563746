import { CanActivate, Router } from '@angular/router';
import { Injectable } from '@angular/core';
import { Observable } from "rxjs/Observable";
import { of } from 'rxjs/observable/of';
import { catchError } from "rxjs/operators/catchError";
import 'rxjs/Rx';

// Service
import { AuthenticationService } from './authentication.service';

@Injectable()
export class SignUpAvailabilityGuard implements CanActivate {

  constructor(private _router: Router,
    private _auth: AuthenticationService) { }

  canActivate(): Observable<boolean>|boolean {
    return this._auth.checkSignUpAvailability()
      .pipe(catchError(error => of(false))).map(res => {
        if (res !== false) {
          return true;
        } else {
          this._router.navigate(['/sign-in']);
          return false;
        }
      });
  }
}
