import { Component, OnInit, ViewChild, TemplateRef } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';

// Components
import { PopupComponent } from '../popup/popup.component';

// Models
import { AuthorModel, InvitationModel } from '../models/author.model';
import { UserModel, UserRoleLevels, UserRoleModel, UserRoles } from '../models/user.model';

// Services
import { AuthorsService } from './authors.service';
import { AuthenticationService } from '../authentication/authentication.service';
import { NotificationsService } from '../notifications/notifications.service';

@Component({
  selector: 'app-authors',
  templateUrl: './authors.component.html',
  styleUrls: ['./authors.component.css']
})
export class AuthorsComponent implements OnInit {

  public authorList: Array<AuthorModel> = <Array<AuthorModel>>[];
  public authorSelected: AuthorModel = <AuthorModel>{};
  public userRoles: Array<UserRoleModel> = <Array<UserRoleModel>>[];
  public selectedRole: string = null;
  public email: string = null;
  public member: UserModel = null;

  private _memberRoleLevel: number = -1;
  @ViewChild('inviteModal') inviteModal: ModalDirective;

  constructor(private _authentication: AuthenticationService, 
    private _authors: AuthorsService,
    private _notifications: NotificationsService) { }

  ngOnInit() {
    this.getAuthorsList();
    this.member = this._authentication.member;

    Object.keys(UserRoleLevels).forEach(key => {
      if (this.member.role == key) this._memberRoleLevel = Number(UserRoleLevels[key]);
      if (this._memberRoleLevel >= Number(UserRoleLevels[key]))
        this.userRoles.push({
          key: key,
          value: UserRoleLevels[key]
        });
    });
    Object.keys(UserRoles).forEach(key => {
      this.userRoles.forEach(item => {
        if (key === item.key) item.key = UserRoles[key];
      })
    })
    this.selectedRole = this.member.role;
  }

  public getAuthorsList() {
    this._authors.getAuthorsList().subscribe(
      (res) => { this.authorList = <Array<AuthorModel>>res },
      (error) => { console.log(JSON.stringify(error)); }
    );
  }

  public inviteAuthor(email: string) {
    this._authors.inviteUser(email, this.selectedRole).subscribe(
      (res) => { 
        this.hideInviteModal(); 
        this._notifications.message.emit('Invitation was sent.');
      },
      (err) => { console.log(JSON.stringify(err)); }
    );
  }

  public hideInviteModal(): void {
    this.inviteModal.hide();
  }
}
