import { SocialLinkModel } from './socialLink.model';
import { NavigationItemModel } from './navigationItem.model';

export enum LayoutEntityPositions {
  Header = 'Header',
  Footer = 'Footer'
}

export enum LayoutEntityContexts {
  Default = 'Default',
  Posts = 'Multiple posts',
  Post = 'A single post',
  Authors = 'Multiple authors',
  Author = 'A single author',
  Page = 'A custom page',
  StaticPages = 'A static page'
}

export interface LayoutEntityModel {
  context: string;
  position: string;
  content: string;
  contentHtml?: string;
  styles: string;
  scripts: string;
}

export interface SettingsModel {
  appName: string;
  earlyBirdDate: Date;
  registrationDeadline: Date;
  edition: number;
  socialLinks: Array<SocialLinkModel>;
  navigation: Array<NavigationItemModel>;
  layout: Array<LayoutEntityModel>;
  localization: any;
}

export interface ContextModel {
  key: string;
  value: string;
}