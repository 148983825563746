import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

// Models
import { LayoutEntityModel, SettingsModel } from "../models/settings.model";

@Injectable()
export class SettingsService {

    constructor(private _http: HttpClient) { }

    public fetch() {
        return this._http.get('settings');
    }

    public update(settings: SettingsModel ) {
        return this._http.put('settings', settings);
    }

    public fetchLayout(context ) {
        return this._http.get(`settings/layout/${context}`);
    }

    public updateLayout(settings: SettingsModel ) {
        return this._http.put('settings/layout', settings);
    }

    public migrate(edition: number | string) {
        return this._http.patch(`settings/migrate`, { edition: edition });
    }
}