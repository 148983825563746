import { Component, OnInit } from '@angular/core';

// Services
import { AuthenticationService } from '../authentication.service';

// Models
import { CredentialsModel } from '../../models/credentials.model';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.css'],
  providers: [AuthenticationService]
})
export class SignInComponent implements OnInit {

  public credentials: CredentialsModel = <CredentialsModel>{};
  public usernameError = false;
  public passwordError = false;
  private token: string;

  constructor(private _authentication: AuthenticationService) { }

  ngOnInit() {
  }

  public submit() {
    if (!this.credentials.username || this.credentials.username === ''
      || !this.credentials.password || this.credentials.password === '') {
      return false;
    }

    this._authentication.signIn(this.credentials).subscribe(
      (res) => { },
      (err) => {
        console.log(JSON.stringify(err));
        if (err.status === 404) this.usernameError = true;  
        if (err.status === 401) this.passwordError = true;
      }
    );
  }

}
