import { Component, forwardRef, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { NavigationItemModel } from '../../models/navigationItem.model';

@Component({
  selector: 'app-navigation-thumbnail',
  templateUrl: './navigation-thumbnail.component.html',
  styleUrls: ['./navigation-thumbnail.component.css']
})
export class NavigationThumbnailComponent implements OnInit {

  @Input() navigationItem;
  @Output() onDelete = new EventEmitter<Object>();
  @Output() onDefaultChange = new EventEmitter<Object>();

  constructor() { }

  ngOnInit() {
  }

  public addNewChildItem() {
    this.navigationItem.children.push(<NavigationItemModel>{});
  }

  public deleteNavigationItem(item) {
    this.onDelete.emit(item);
  }

  public changeDefaultMainPage(item) {
    this.onDefaultChange.emit(item);
  }

}
