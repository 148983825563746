export enum UserRoles {
  Admin = 'Admin',
  Organizer = 'Organizer'
}

export enum UserRoleLevels {
  Admin = '1',
  Organizer = '0'
}

export interface UserModel {
  _id: string;
  password: string;
  name: string;
  email: string;
  username: string;
  role: string;
  summary: string;
  image: any;
  content: string;
  contentHtml: string;
  localization: any;
}

export interface UserRoleModel {
  key: string;
  value: number;
}
