import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';

// Models
import { LayoutEntityModel, LayoutEntityContexts, LayoutEntityPositions, SettingsModel, ContextModel } from '../models/settings.model';

// Services
import { SettingsService } from '../settings/settings.service';
import { NotificationsService } from '../notifications/notifications.service';

@Component({
  selector: 'app-layout',
  templateUrl: './layout.component.html',
  styleUrls: ['./layout.component.css']
})
export class LayoutComponent implements OnInit {

  public settings: SettingsModel = <SettingsModel>{};
  public header: LayoutEntityModel = <LayoutEntityModel>{};
  public footer: LayoutEntityModel = <LayoutEntityModel>{};
  public activeHeaderContentWindow: string = 'html';
  public activeFooterContentWindow: string = 'html';
  public layoutContexts: Array<ContextModel> = <Array<ContextModel>>[];
  public selectedContext: ContextModel = <ContextModel>{};

  constructor(private _settings: SettingsService,
    private _notifications: NotificationsService) { }

  ngOnInit() {
    Object.keys(LayoutEntityContexts).forEach(key => {
      this.layoutContexts.push({
        key: key,
        value: LayoutEntityContexts[key]
      });
    });
    this.selectedContext = this.layoutContexts[0];
    this.fetch();
  }

  fetch() {
    this._settings.fetchLayout(this.selectedContext.key).subscribe(
      (res) => { 
        this.settings = <SettingsModel>res; 
        this.header = this.settings.layout.filter((layoutEntity: LayoutEntityModel) => layoutEntity.context === LayoutEntityContexts.Default 
          && layoutEntity.position === LayoutEntityPositions.Header)[0] || <LayoutEntityModel>{};
        this.footer = this.settings.layout.filter((layoutEntity: LayoutEntityModel) => layoutEntity.context === LayoutEntityContexts.Default 
          && layoutEntity.position === LayoutEntityPositions.Footer)[0] || <LayoutEntityModel>{};
      },
      (err) => { console.log(JSON.stringify(err)) }
    )
  }

  save() {
    this.settings.layout = [
      <LayoutEntityModel>{
        context: this.selectedContext.key,
        position: LayoutEntityPositions.Header,
        content: this.header.content,
        styles: this.header.styles,
        scripts: this.header.scripts
      },
      <LayoutEntityModel>{
        context: this.selectedContext.key,
        position: LayoutEntityPositions.Footer,
        content: this.footer.content,
        styles: this.footer.styles,
        scripts: this.footer.scripts
      }
    ];
    this._settings.updateLayout(this.settings).subscribe(
      (res) => { this._notifications.message.emit('Layout was saved.'); },
      (err) => { console.log(JSON.stringify(err)) }
    );
  }

  public buildIframeContent(sectionCode) {
    let position = sectionCode === 'header' ? this.header : this.footer;
    let htmlContent = position.content;
    if (position.content == null) { htmlContent = ''; }
    const html = `
    <head>
      <link rel="stylesheet" type="text/css" href="https://fonts.googleapis.com/css?family=Raleway:100,200,300,400,500|David+Libre:400,500|Poppins:600|Martel:200,600|Open+Sans:400,600,700">
      <link rel="stylesheet" type="text/css" href="https://use.fontawesome.com/releases/v5.0.12/css/all.css">
      <style>${position.styles}</style>
    </head>
    <body>
      ${htmlContent}
      <script>${position.scripts}</script>
    </body>
    `;
    return 'data:text/html;charset=utf-8,' + encodeURI(html);
  }

  public contextSelected(item) {
    this._settings.fetchLayout(item.key).subscribe(
      (res) => { 
        this.selectedContext = item;
        const contextLayout = <SettingsModel>res;
        this.header = contextLayout.layout[0];
        this.footer = contextLayout.layout[1];
       },
      (err) => { console.log(JSON.stringify(err)) }
    );
  }
}
