import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';

// Models
import { SettingsModel } from '../models/settings.model';
import { NavigationItemModel } from '../models/navigationItem.model';

@Injectable()
export class NavigationService {

  public $delete: EventEmitter<NavigationItemModel> = new EventEmitter();

  constructor(private _http: HttpClient) { }

  public getNavigation() {
    return this._http.get('settings/navigation');
  }

  public updateNavigation(settings: SettingsModel) {
    return this._http.put('settings/navigation', settings);
  }
}
