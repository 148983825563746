import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-notification-thumbnail',
  templateUrl: './notification-thumbnail.component.html',
  styleUrls: ['./notification-thumbnail.component.css']
})
export class NotificationThumbnailComponent implements OnInit {

  @Input() message: string;
  @Output() onClose: EventEmitter<any> = new EventEmitter();
  public visible: boolean = false;

  constructor() { }

  ngOnInit() {
    if(this.message) this.visible = true;
    setTimeout(() => { this.close() }, 5000);
  }

  close() {
    this.visible = false;
    this.onClose.emit(null);
  }

}
