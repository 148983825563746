import { Component, OnInit, ViewChild } from '@angular/core';
import { Papa } from 'ngx-papaparse';

// Models
import { AccountItemModel } from '../models/accountItem.model';
import { AccountsModel } from '../models/accounts.model';

// Services
import { AccountsService } from './accounts.service';
import { NotificationsService } from '../notifications/notifications.service';
import { ModalDirective } from 'ngx-bootstrap';

@Component({
  selector: 'app-accounts',
  templateUrl: './accounts.component.html',
  styleUrls: ['./accounts.component.css']
})
export class AccountsComponent implements OnInit {

  public allAccounts: Array<AccountItemModel> = <Array<AccountItemModel>>[];
  public accountList: Array<AccountItemModel> = null;
  public accounts: AccountsModel = <AccountsModel>{};
  public currentPage: number = 1;
  public loading: boolean = true;
  public searchText: string = null;
  public registered: boolean = false;
  public currentEdition: boolean = true;
  public defaultCount: number = 25;
  public amountPaid: number = null;
  private _selectedAccount: AccountItemModel = null;

  @ViewChild('paidAmountModal') paidAmountModal: ModalDirective;

  constructor(private _papaParse: Papa,
    private _accountService: AccountsService,
    private _notifications: NotificationsService) { }

  ngOnInit() {
    this.getUserList();
    this.loading = false;
  }

  public getUserList() {
    this.loading = true;
    this._accountService.getClientUsers(this.searchText, this.registered, this.currentEdition, (this.currentPage-1)*this.defaultCount, this.defaultCount).subscribe(
      (res) => { 
        this.accounts = <AccountsModel>res;
        this.allAccounts = this.accounts.data;
        this.loading = false;
        this._assignCopy();
       },
      (err) => { console.log(JSON.stringify(err)); }
    )
  }
  
  public paginationChange() {
    if (this.currentPage < 1) this.currentPage = 1;
    if (this.currentPage > Math.ceil(this.accounts.totalCount / this.defaultCount)) this.currentPage = Math.ceil(this.accounts.totalCount / this.defaultCount);
    this.getUserList();
  }

  public previousPage() { 
    this.currentPage--;
    this.paginationChange();
    this.getUserList();
  }

  public nextPage() {
    this.currentPage++;
    this.paginationChange();
    this.getUserList();
  }

  public generateCSV() {
    this._accountService.getExportClientUsers(this.searchText, this.registered, this.currentEdition).subscribe(
      (res) => { 
        this.downloadCSVFile(<Array<AccountItemModel>>res);
      },
      (err) => { console.log(err); }
    );
    
  }

  public downloadCSVFile(templateList) {
    var csvTemplate = this._papaParse.unparse(templateList, {
      header: true
    });
    var blob = new Blob([csvTemplate], {type: 'text/csv' })
    let url = window.URL.createObjectURL(blob);

    if (navigator.msSaveOrOpenBlob) {
      navigator.msSaveBlob(blob, 'accounts.csv');
    } else {
      let a = document.createElement('a');
      a.href = url;
      a.download = 'accounts.csv';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
    window.URL.revokeObjectURL(url);
  }

  public showRegisteredUseres(event) {
    this.registered = event.target.checked;
    this.getUserList();
  }

  public showCurrentEdition(event) {
    this.currentEdition = !event.target.checked;
    this.getUserList();
  }
  public showSearchedUsers(event) {
    this.searchText = event.target.value;
    this.getUserList();
  }

  public changeRegistrationPermissionOrAcceptPaper(account, prop) {
    this._accountService.editClientUser(account._id,
      (prop === 'canRegister') ?
      <AccountItemModel>{
        canRegister: !account.canRegister
      }
      : 
      <AccountItemModel>{
        paperAccepted: !account.paperAccepted
      }
    ).subscribe(
      (res) => {
        this._notifications.message.emit('Changes saved.');
        this.getUserList();
      },
      (err) => { console.log(JSON.stringify(err)); }
    );
  }

  public openAmountPaidModal(account, $event) {
    $event.preventDefault()
    this._selectedAccount = account;
    if (!account.registrationDetails || (account.registrationDetails && account.registrationDetails.description === '')) {
      this.paidAmountModal.show();
    }
    else {
      this._selectedAccount.registrationDetails = null;
      this.markUserAsPaid();
    }
      
  }

  private markUserAsPaid() {
    this._accountService.editClientUser(this._selectedAccount._id,
      this.amountPaid ? 
      <AccountItemModel>{
        registrationDetails: {
          details: this._selectedAccount.registrationDetails === 'Bank Wire' ? null : 'Bank Wire',
          amount: this.amountPaid
        }
      }
      : <AccountItemModel>{
        registrationDetails: null
      }
    ).subscribe(
      (res) => {
        this._notifications.message.emit('Changes saved.');
        this.getUserList();
        this.paidAmountModal.hide();
        this.amountPaid = null;
      },
      (err) => { console.log(JSON.stringify(err)); }
    );
  }

  private _assignCopy() {
    this.accountList = this.allAccounts;
  }
}
