import { Component, OnInit } from '@angular/core';

// Services
import { AuthorsService } from '../authors.service';

// Models
import { InvitationModel } from '../../models/author.model';
import { equal } from 'assert';

@Component({
  selector: 'app-invitations',
  templateUrl: './invitations.component.html',
  styleUrls: ['./invitations.component.css']
})
export class InvitationsComponent implements OnInit {

  public invitationList: Array<InvitationModel> = <Array<InvitationModel>>[];
  public temporary: Array<InvitationModel> = <Array<InvitationModel>>[];

  constructor(private _authors: AuthorsService) { }

  ngOnInit() {
    this.getInvitationsList();
  }

  public getInvitationsList() {
    this._authors.getInvitationList().subscribe(
      (res) => { 
        this.invitationList = <Array<InvitationModel>>res;
        this.invitationList.reverse();
        // this.invitationList.length = 0;
        // const map = new Map();
        // for (const item of this.temporary) {
        //   if(!map.has(item.email)){
        //     map.set(item.email, true);
        //     this.invitationList.push({
        //       _id: item._id,
        //       date: item.date,
        //       email: item.email,
        //       by: item.by,
        //       code: item.code,
        //       deleted: item.deleted,
        //       expired: item.expired,
        //     });
        //   }
        // }
      },
      (err) => { console.log(JSON.stringify(err)); }
    )
  }

}
