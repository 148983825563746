import { Component, OnInit, Output, EventEmitter, ViewChild } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
// import * as decode from 'jwt-decode';

//Components
import { PostThumbnailComponent } from './post-thumbnail/post-thumbnail.component';
import { PopupComponent } from '../popup/popup.component';

//Models
import { PostModel } from '../models/post.model';
import { UserModel } from '../models/user.model';

//Services
import { PostsService } from './posts.service';
import { NotificationsService } from '../notifications/notifications.service';
import { AuthenticationService } from '../authentication/authentication.service';

@Component({
  selector: 'app-posts',
  templateUrl: './posts.component.html',
  styleUrls: ['./posts.component.css']
})
export class PostsComponent implements OnInit {
  
  public postList: Array<PostModel> = <Array<PostModel>>[];
  public selectedPostItem: PostModel = null;
  public member: UserModel = null;

  constructor(private _http: HttpClient,
    private _router: Router,
    private _posts: PostsService,
    private _notification: NotificationsService,
    private _authentication: AuthenticationService) { }

  ngOnInit() {
    this.getPostList();
    this.member = this._authentication.member;
  }

  public getPostList() {
    this._posts.getPostsList().subscribe(
      (res) => { this.postList = <Array<PostModel>>res; },
      (err) => { console.log(JSON.stringify(err)); }
    );
  }

  public postPreview(postItem: PostModel) {
    this.selectedPostItem = postItem;
  }

  public deletePost(postItem: PostModel) {
    this._posts.deletePost(postItem._id).subscribe(
      (res) => { 
        this._notification.message.emit("Post was deleted successfully");
        this.getPostList();
      },
      (error) => { JSON.stringify(error); }
    );    
  }

  public createNewPost() {
    this._router.navigate([`/post-profile/new-post`]);
  }
}
