import { Component, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { ModalDirective } from 'ngx-bootstrap';
import { Router } from '@angular/router';

// Components
import { PopupComponent } from '../popup/popup.component';

// Models
import { NavigationItemModel } from '../models/navigationItem.model';
import { SettingsModel } from '../models/settings.model';

// Services
import { NavigationService } from './navigation.service';
import { NotificationsService } from '../notifications/notifications.service';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class NavigationComponent implements OnInit {

  public settings: SettingsModel = <SettingsModel>{};

  @ViewChild('savePopup') savePopup: PopupComponent;
  @ViewChild('deletePopup') deletePopup: PopupComponent;

  constructor(private _navigation: NavigationService,
    private _notifications: NotificationsService,
    private _router: Router) { }

  ngOnInit() {
    this.getNavigation();
  }

  public getNavigation() {
    this._navigation.getNavigation().subscribe(
      (res: SettingsModel) => {
        this.settings = <SettingsModel>res;
      },
      (error) => { console.log(JSON.stringify(error)); }
    );
  }

  public newNavigationItem() {
    this.settings.navigation.push(<NavigationItemModel>{});
  }

  public removeEmptyAndSave() {
    this.settings.navigation.forEach((element: NavigationItemModel, index: number, arr: Array<NavigationItemModel>) => {
      if (Object.keys(element).length === 0 || (element && !element.name || !element.url)) { arr.splice(index, 1); }
      if (element.children) {
        element.children.forEach((childElement: NavigationItemModel, subIndex: number, childrenArr: Array<NavigationItemModel>) => {
          if (Object.keys(childElement).length === 0 || (childElement && !childElement.name || !childElement.url)) {
            childrenArr.splice(subIndex, 1);
          }
        });
      }
    });
    this._navigation.updateNavigation(this.settings).subscribe(
      (res) => { 
        this._notifications.message.emit('Navigation saved.'); 
        this.getNavigation();
      },
      (err) => { console.log(JSON.stringify(err)); }
    );
  }

  public deleteNavItem(item, list = this.settings.navigation) {
    list.forEach((subNavigationItem: NavigationItemModel, subIndex: number, subList: Array<NavigationItemModel>) => {
      if (subNavigationItem._id === item._id) { 
        subList.splice(subIndex, 1); 
        return true;
      }
      else if (subNavigationItem.children) {
        this.deleteNavItem(item, subNavigationItem.children);
      }
    });  
  }

  public removeDefaultProperty(list = this.settings.navigation) {
    list.forEach((subNavigationItem: NavigationItemModel, subIndex: number, subList: Array<NavigationItemModel>) => {
      subNavigationItem.default = false;
      if (subNavigationItem.children) {
        this.removeDefaultProperty(subNavigationItem.children);
      }
    });  
  }

  public changeDefaultMainPage(item) {
    this.removeDefaultProperty();
    item.default = true;
    this._navigation.updateNavigation(this.settings).subscribe(
      (res) => { 
        this._notifications.message.emit('Default page was changed.'); 
        this.getNavigation();
      },
      (err) => { console.log(JSON.stringify(err)); }
    );
  }

  public isSaveDisabled() {
    let enable = false;
    this.settings.navigation.forEach((element: NavigationItemModel) => {
      if (element.name && !element.url || (!element.name && element.url)) { enable = true; }
      if (element.children) {
        element.children.forEach((childElement: NavigationItemModel) => {
          if (childElement.name && !childElement.url || (!childElement.name && childElement.url)) { enable = true; }
        });
      }
    });
    return enable;
  }
}
